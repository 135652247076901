<template>
    <AccountingReports />
</template>

<script>
import AccountingReports from '@apps/AccountingReports'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        AccountingReports
    }
}
</script>